// const _serviceAccountingBaseUrl = process.env.REACT_APP_SERVICE_ACCOUNTING_BASE_URL;
// const _vehicleAccountingBaseUrl = process.env.REACT_APP_VEHICLE_ACCOUNTING_BASE_URL;

const settings = (permissions, role = null) => ({
	pages: {
		id: 'pages',
		text: 'Settings',
		icon: 'Extension',
	},
	singlePages: {
		id: 'settingsPage',
		text: 'Settings',
		path: 'settings',
		icon: 'SettingsApplications',
		subMenu: {
			user: {
				id: 'users',
				text: 'User',
				path: 'settings/users?page=1&limit=20',
				icon: 'AccountCircle',
				hide: !permissions?.['user.index'],
			},
			role: {
				id: 'roles',
				text: 'Role',
				path: 'settings/roles',
				icon: 'Lock',
				hide: !permissions?.['role.index'],
			},
			country: {
				id: 'country',
				text: 'Country',
				path: 'settings/countries?page=1&limit=20',
				icon: 'AddLocation',
				hide: !permissions?.['country.index'],
			},
			state: {
				id: 'state',
				text: 'State',
				path: 'settings/states?page=1&limit=20',
				icon: 'AddLocation',
				hide: !permissions?.['state.index'],
			},
			city: {
				id: 'city',
				text: 'City',
				path: 'settings/cities?page=1&limit=20',
				icon: 'AddLocation',
				hide: !permissions?.['city.index'],
			},
			location: {
				id: 'location',
				text: 'Location',
				path: 'settings/locations?page=1&limit=20',
				icon: 'AddLocation',
				hide: !permissions?.['location.index'],
			},
			ports: {
				id: 'ports',
				text: 'Ports',
				path: 'settings/ports?page=1&limit=20',
				icon: 'AddLocation',
				hide: !permissions?.['ports.index'],
			},
			auditLog: {
				id: 'auditLog',
				text: 'Audit Log',
				path: 'settings/audit-logs?page=1&limit=20',
				icon: 'History',
				hide: role !== 1,
			},
		},
	},
});

// const accounts = (permissions, user) => ({
// 	pages: {
// 		id: 'accountspages',
// 		text: 'Accounting',
// 		icon: 'Calculate',
// 	},
// 	singlePages: {
// 		id: 'accountsPage',
// 		text: 'Accounting',
// 		path: 'accounts',
// 		icon: 'Calculate',
// 		subMenu: {
// 			vehicleInvoice: {
// 				id: 'vehicle-invoice',
// 				text: 'Vehicle Invoice',
// 				path: 'accounts/vehicle-invoice?page=1&limit=20',
// 				icon: 'Inventory',
// 				hide: !permissions?.['invoices.vehicle_invoice'],
// 			},

// 			serviceInvoice: {
// 				id: 'service-invoice',
// 				text: 'Service Invoice',
// 				path: 'accounts/service-invoice?page=1&limit=20',
// 				icon: 'Inventory',
// 				hide: !permissions?.['invoices.service_invoice'],
// 			},

// 			vehicleAccounting: {
// 				id: 'vehicle-ccounting',
// 				text: 'Vehicle',
// 				icon: 'Inventory',
// 				hide: !permissions?.['accounting.vehicle'],
// 				new_tab_open: `${_vehicleAccountingBaseUrl}/auth/guest/login/${user?.id}`,
// 			},

// 			serviceAccounting: {
// 				id: 'service-ccounting',
// 				text: 'Service',
// 				icon: 'Inventory',
// 				hide: !permissions?.['accounting.service'],
// 				new_tab_open: `${_serviceAccountingBaseUrl}/auth/guest/login/${user?.id}`,
// 			},
// 		},
// 	},
// });

const accounts = (permissions) => ({
	pages: {
		id: 'accountspages',
		text: 'Accounting',
		icon: 'Calculate',
	},
	singlePages: {
		id: 'accountsPage',
		text: 'Accounting',
		path: 'accounts',
		icon: 'Calculate',
		subMenu: {
			InvoiceSummery: {
				id: 'invoice',
				text: 'Invoice Summary',
				path: 'accounts/invoice-summary?page=1&limit=20',
				icon: 'Inventory',
				hide: !permissions?.['invoices.index'],
			},
			PaidInvoice: {
				id: 'paidInvoice',
				text: 'Paid Invoice',
				path: 'accounts/paid-invoice?page=1&limit=20',
				icon: 'Paid',
				hide: !permissions?.['invoices.paid_invoice'],
			},
			PartialPaidInvoice: {
				id: 'partialPaidInvoice',
				text: 'Partial Paid Invoice',
				path: 'accounts/invoices/partially-paid?page=1&limit=20',
				icon: 'Palette',
				hide: !permissions?.['invoices.partially_paid_invoice'],
			},
			UnpaidInvoice: {
				id: 'unpaidInvoice',
				text: 'Unpaid Invoice',
				path: 'accounts/invoices/unpaid?page=1&limit=20',
				icon: 'Unpublished',
				hide: !permissions?.['invoices.unpaid_invoice'],
			},
		},
	},
});

const vehicle = (permissions) => ({
	singlePages: {
		id: 'vehicle',
		text: 'Vehicle',
		path: 'vehicles?page=1&limit=20',
		icon: 'DirectionsCar',
		subMenu: null,
		hide: !permissions?.['vehicle.index'],
	},
});

const exports = (permissions) => ({
	singlePages: {
		id: 'export',
		text: 'Export',
		path: 'exports?page=1&limit=20',
		icon: 'ImportExport',
		subMenu: null,
		hide: !permissions?.['export.index'],
	},
});

const consignee = (permissions) => ({
	singlePages: {
		id: 'consignee',
		text: 'Consignee',
		path: 'consignees?page=1&limit=20',
		icon: 'DirectionsCar',
		subMenu: null,
		hide: !permissions?.['consignee.index'],
	},
});

const profiles = () => ({
	singlePages: {
		id: 'profilePage',
		text: 'Profiles',
		path: 'profiles',
		icon: 'Quickreply',
		subMenu: null,
		// hide: !permissions?.['faq.index'],
	},
});

const customers = (permissions) => ({
	singlePages: {
		id: 'customers',
		text: 'Customers',
		path: 'customers?page=1&limit=20',
		icon: 'AccountCircle',
		subMenu: null,
		hide: !permissions?.['customers.index'],
	},
});

const containers = (permissions) => ({
	singlePages: {
		id: 'containersPage',
		text: 'Containers',
		path: 'containers?page=1&limit=20',
		icon: 'LocalShipping',
		subMenu: null,
		hide: !permissions?.['container.index'],
	},
});

const complains = (permissions) => ({
	singlePages: {
		id: 'complainsPage',
		text: 'Comments',
		path: 'complains?page=1&limit=20',
		icon: 'InsertDriveFile',
		subMenu: null,
		hide: !permissions?.['complains.index'],
	},
});

const price = (permissions) => ({
	singlePages: {
		id: 'price',
		text: 'Prices',
		path: 'prices',
		icon: 'AlignVerticalBottom',
		hide: !permissions?.['price.index'],
	},
});

const pricing = (permissions, role) => ({
	singlePages: {
		id: 'pricingPage',
		text: 'Pricing',
		path: 'pricing',
		icon: 'ProductionQuantityLimits',
		hide: !permissions?.['price.index'] || role === 4,
		subMenu: {
			shippingRate: {
				id: 'shippingRate',
				text: 'Shipping Rate',
				path: 'shipping-rates?page=1&limit=20',
				icon: 'AlignVerticalBottom',
				hide: !permissions?.['price.index'] || role === 4,
			},
			towingingRate: {
				id: 'towing',
				text: 'Towing Rate',
				path: 'towing-rates?page=1&limit=20',
				icon: 'RateReview',
				hide: !permissions?.['price.index'] || role === 4,
			},
			clearanceRate: {
				id: 'clearanceRate',
				text: 'Clearance Rate',
				path: 'clearance-rate',
				icon: 'AddModerator',
				hide: !permissions?.['clearance_rates.index'] || role === 4,
			},
		},
	},
});

const loadPlan = (permissions) => ({
	singlePages: {
		id: 'loadPlan',
		text: 'Load Plan',
		path: 'load-plans?page=1&limit=20',
		icon: 'NextPlan',
		subMenu: null,
		hide: !permissions?.['load-plans.index'],
	},
});

export {
	accounts,
	complains,
	settings,
	vehicle,
	profiles,
	consignee,
	customers,
	exports,
	containers,
	pricing,
	loadPlan,
	price,
};
